import { WindowsFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getAllMySubscriptionsAction } from './apiAction';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  billingCycleEnums,
  currencyEnums,
  getKeyByValue,
  logoutUser,
} from '../../Utils/setting';

export default function SubscriptionDetails() {
  // Cache Memmory
  const cacheActiveSub = localStorage.getItem('activeSubscription');

  const navigate = useNavigate();
  const [key, setKey] = useState('');
  // const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [activeSubscription, setActiveSubscription] = useState(
    cacheActiveSub ? JSON.parse(cacheActiveSub) : []
  );

  // const [key, setKey] = useState('');
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  const getMySubscription = async () => {
    await getAllMySubscriptionsAction()
      .then(({ message, error, data }) => {
        if (!error) {
          const activeSub = data.filter((vv) => vv.is_subscription_active);

          console.log('activeSub', activeSub);

          if (activeSub.length === 0) {
            localStorage.removeItem('activeSubscription');
          } else {
            setActiveSubscription(activeSub);
            localStorage.setItem(
              'activeSubscription',
              JSON.stringify(activeSub)
            );
          }
        } else {
          notification.error({ message });
        }
      })
      .catch((error) => {
        logoutUser({ navigate: navigate, error: error });
      });
  };

  useEffect(() => {
    getMySubscription();
  }, []);

  return (
    <>
      {activeSubscription.length === 0 ? (
        <>
          <h2 className='text-3xl font-semibold mt-10'>
            No Active Subscription
          </h2>
          <p className='mt-5 text-center'>
            Currently, there is no active subscription. To review pricing,
            please click on the following link:{' '}
            <span
              onClick={() => navigate('/pricing')}
              className=' text-primary font-semibold hover:underline hover:cursor-pointer hover:text-primary_highlight '
            >
              Click Here
            </span>
            .
          </p>
        </>
      ) : (
        <>
          {activeSubscription.map((vv) => (
            <>
              <h2 className='text-3xl font-semibold mt-10'>
                Current Subscription
              </h2>

              <div className='p-10 flex flex-row justify-between'>
                <div
                  style={{
                    minHeight: 250,
                  }}
                  className='mx-5 bg-purple-500 text-white rounded-lg border-4 border-primary w-56 relative drop-shadow-default bg-secondary hover:scale-105 transition-transform duration-300'
                >
                  <div className='p-4 pt-10 pb-10'>
                    <h1 className='text-2xl font-bold text-center'>
                      {getKeyByValue(billingCycleEnums, vv.billing_cycle)}
                    </h1>
                    <div className='text-black mt-4 text-5xl font-semibold relative ml-2 flex flex-row items-center justify-center text-primary '>
                      <p className='text-center relative'>
                        {' '}
                        <span
                          style={{
                            left: -10,
                          }}
                          className='absolute text-base font-normal text-primary'
                        >
                          {vv.currency === currencyEnums['INR'] ? '₹' : '$'}
                        </span>
                        {vv.subscription_price}
                      </p>
                    </div>
                    {/* <p className='text-black mt-4 text-center'>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quis voluptate libero, omnis nesciunt.
                    </p> */}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '1rem',
                      width: '100%',
                      bottom: -22,
                    }}
                    className='absolute '
                  >
                    <button className='shine-button shine-button-disabled text-white py-2 px-4 rounded bg-primary_button text-button_primary w-40 rounded-l-3xl rounded-r-3xl drop-shadow-default font-semibold'>
                      {vv.is_subscription_paused ? 'Paused' : 'Active Plan'}
                    </button>
                  </div>
                </div>
              </div>
            </>
          ))}

          <p className='text-center text-xs  mb-10'>
            To manage your current plan, including options to resume, pause, or
            cancel, please{' '}
            <span
              onClick={() => navigate('/action')}
              className=' text-primary font-semibold hover:underline hover:cursor-pointer hover:text-primary_highlight '
            >
              click here
            </span>
            .{' '}
          </p>

          <div
            className='text-center flex flex-col mt-5'
            style={{ width: '90%' }}
          >
            <p
              className='text-lg font-semibold'
              style={{
                letterSpacing: 1,
              }}
            >
              Your Subscription Key
            </p>
            {activeSubscription[0].is_subscription_paused ? (
              <div className='bg-primary h-9 rounded-xl flex items-center justify-center p-2'>
                <p>
                  To access your key, please resume your subscription, which is
                  currently on pause.
                </p>
              </div>
            ) : (
              <>
                {activeSubscription[0].subscription_key_details?.app_key ? (
                  <div className='flex flex-1 justify-center mb-5 items-center'>
                    <div className='text-overflow bg-tertiary h-9 rounded-xl flex flex-row justify-center px-5 items-center p-2 w-96'>
                      {activeSubscription[0].subscription_key_details?.app_key}
                    </div>
                    <CopyToClipboard
                      text={
                        activeSubscription[0].subscription_key_details?.app_key
                      }
                      onCopy={handleCopy}
                    >
                      <button className='ml-2 px-4 py-1 bg-secondary text-white rounded-md border-2'>
                        {copied ? 'Copied!' : 'Copy'}
                      </button>
                    </CopyToClipboard>
                  </div>
                ) : (
                  <div className='bg-primary h-9 rounded-xl flex items-center justify-center p-2'>
                    <p>
                      Unable to retrieve the key at the moment. Please try again
                      later.
                    </p>
                  </div>
                )}

                {/* Download Section */}
                <div
                  className='text-center flex flex-col mt-5'
                  style={{ width: '90%' }}
                >
                  <p>
                    {' '}
                    Get ahead with our cutting-edge software by downloading the
                    latest version today!
                  </p>
                  <div className='flex flex-row flex-1 justify-center mb-10'>
                    <a
                      href='https://the.earth.li/~sgtatham/putty/latest/w64/putty-64bit-0.79-installer.msi'
                      download='putty-installer.msi'
                      className='px-10 text-primary py-3 rounded mt-5 border-2 hover:bg-primary_button hover:text-button_primary hover:border-primary active:bg-primary'
                      style={{ transition: 'all 0.1s ease-in' }}
                    >
                      <WindowsFilled
                        className=''
                        style={{
                          fontSize: 50,
                        }}
                      />
                      <p className='font-semibold'>Windows Installer</p>
                    </a>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}