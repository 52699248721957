import { ConfigProvider } from 'antd';
import './App.css';
import Layout from './Utils/Layout';

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          // // Seed Token
          // colorPrimary: '#00b96b',
          // borderRadius: 2,
          // // Alias Token
          // colorBgContainer: '#f6ffed',
        },
        components: {
          Spin: {
            colorPrimary: '#FFFFFF',
            colorBgContainer: '#00aeff0'
          },
        },
      }}
    >
      <Layout />
    </ConfigProvider>
  );
}

export default App;
