import { API_URL } from "../../../Utils/setting";

export const signUpUrl = API_URL + '/users/add'

export const signInUrl = API_URL + '/auth/login'

export const getMeUrl = API_URL + '/users/me'

export const resetPasswordRequestUrl = API_URL + '/auth/reset-password-request'

export const setNewPasswordUrl = API_URL + '/auth/set-new-password'