import React, { useEffect, useState } from 'react';
import {
  cancelSubscriptionAction,
  getAllMySubscriptionsAction,
  pauseSubscriptionAction,
  resumeSubscriptionAction,
} from './apiAction';
import { Spin, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { billingCycleEnums } from '../../Utils/setting';

export default function Action() {
  // Cache Memmory
  const cacheActiveSub = localStorage.getItem('activeSubscription');

  const navigate = useNavigate();

  const [activeSubscription, setActiveSubscription] = useState([]);
  const [loading, setLoading] = useState(false);

  const getMySubscription = async () => {
    await getAllMySubscriptionsAction()
      .then(({ message, error, data }) => {
        if (!error) {
          const activeSub = data.filter((vv) => vv.is_subscription_active);
          setActiveSubscription(activeSub);

          if (activeSub.length === 0) {
            localStorage.removeItem('activeSubscription');
          } else {
            localStorage.setItem(
              'activeSubscription',
              JSON.stringify(activeSub)
            );
          }
        } else {
          notification.error({ message });
        }
      })
      .catch((err) => console.log('err', err));
  };

  const cancelSubscription = async () => {
    setLoading(true);

    await cancelSubscriptionAction({
      sub_id: activeSubscription[0].stripe_subscription_id,
    })
      .then(({ error, message }) => {
        if (!error) {
          notification.success({ message });
          getMySubscription();
        } else {
          notification.error({ message });
        }
      })
      .catch((err) => console.log('err', err));

    setLoading(false);
  };

  const pauseSubscription = async () => {
    setLoading(true);
    await pauseSubscriptionAction({
      sub_id: activeSubscription[0].stripe_subscription_id,
    })
      .then(({ error, message }) => {
        // setLoading(false)
        if (!error) {
          notification.success({ message });
          getMySubscription();
        } else {
          notification.error({ message });
        }
      })
      .catch((err) => console.log('err', err));
    setLoading(false);
  };

  const resumeSubscription = async () => {
    setLoading(true);

    await resumeSubscriptionAction({
      sub_id: activeSubscription[0].stripe_subscription_id,
    })
      .then(({ error, message }) => {
        if (!error) {
          notification.success({ message });
          getMySubscription();
        } else {
          notification.error({ message });
        }
      })
      .catch((err) => console.log('err', err));

    setLoading(false);
  };

  useEffect(() => {
    if (cacheActiveSub) setActiveSubscription(JSON.parse(cacheActiveSub));
    else getMySubscription();
  }, []);

  return (
    <>
      {activeSubscription.length === 0 ? (
        <>
          <h2 className='text-3xl font-semibold mt-10'>
            No Active Subscription
          </h2>
          <p className='mt-5 text-center'>
            Currently, there is no active subscription. To review pricing,
            please click on the following link:{' '}
            <span
              onClick={() => navigate('/pricing')}
              className=' text-primary font-semibold hover:underline hover:cursor-pointer hover:text-primary_highlight '
            >
              Click Here
            </span>
            .
          </p>
        </>
      ) : (
        // <Spin size="large" tip="Loading..." spinning={loading}>
        <>
          <h2 className='text-3xl font-semibold mt-10'>Manage Subscription</h2>

          <p className='mb-10'>
            {activeSubscription[0].billing_cycle ===
            billingCycleEnums['LIFETIME']
              ? `Lifetime plans are non-cancelable and cannot be paused.`
              : `Would you like to cancel or temporarily pause your active
            subscription?`}
          </p>

          {activeSubscription[0].billing_cycle !==
            billingCycleEnums['LIFETIME'] && (
            <Spin size='large' tip='Loading...' spinning={loading}>
              <div className=' mb-10 font-sans'>
                <button
                  onClick={cancelSubscription}
                  style={{ transition: 'all 0.1s ease-in', letterSpacing: 1 }}
                  className='drop-shadow-default border-2 px-12 py-2 rounded-md hover:bg-primary_button hover:text-button_primary font-semibold mx-5 '
                >
                  Cancel Subscription
                </button>
                <button
                  onClick={() => {
                    if (activeSubscription[0].is_subscription_paused)
                      resumeSubscription();
                    else pauseSubscription();
                  }}
                  style={{ transition: 'all 0.1s ease-in', letterSpacing: 1 }}
                  className='drop-shadow-default border-2 px-12 py-2 rounded-md hover:bg-primary_button hover:text-button_primary font-semibold mx-5 '
                >
                  {activeSubscription[0].is_subscription_paused
                    ? 'Resume Subscription'
                    : 'Pause Subscription'}
                </button>
              </div>
            </Spin>
          )}

          {/* <Spin size="small" className='text-primary_highlight' /> */}
        </>
      )}
    </>
  );
}
