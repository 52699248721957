import { Button, Form, Input, notification } from 'antd';
import React from 'react';
import { validateEmail } from '../../Utils/setting';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { resetPasswordRequestAction } from './Action';

export default function RequestResetLink() {
  const onFinish = (values) => {
    const params = new URLSearchParams();
    params.append('email', values.email);

    resetPasswordRequestAction({ params })
      .then(({ error, message }) => {
        if (!error) {
          notification.success({
            message:
              'Password reset link sent successfully. Please check your email.',
          });
        } else {
          notification.error({ message });
        }
      })
      .catch((err) => console.log('err', err));
  };

  return (
    <>
      <ExclamationCircleOutlined className=' text-5xl mb-5' />
      <h1 className='text-xl font-bold leading-tight tracking-tight text-center'>
        Forgot Your Password?
      </h1>

      <p className='w-80 text-xs my-3 text-center mb-10'>
        Please provide your email address, and we will promptly send you a link
        to reset your password.
      </p>

      <Form onFinish={onFinish} autoComplete='off' className='w-72 font-sans '>
        {/* <p className=' text-primary text'>Email:</p> */}
        <Form.Item
          name='email'
          rules={[
            {
              required: true,
              message: 'Please enter your email!',
            },
            {
              validator: validateEmail,
            },
          ]}
        >
          <Input placeholder='Your Email' />
        </Form.Item>

        <Form.Item>
          <Button
            // loading={loading}
            htmlType='submit'
            className='w-full h-10 mt-5 text-button_primary bg-primary_button font-medium rounded-lg text-sm py-2.5 text-center'
          >
            Request Link
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
