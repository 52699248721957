import React from 'react';
import {
  Routes,
  Navigate,
  useLocation,
  useNavigate,
  Link,
  Route,
} from 'react-router-dom';

// import { HashRouter as Router, Route, Link } from 'react-router-dom';
// import Subscription from '../Screens/Subscription/Subscription';
import './layout.css';
import SubscriptionDetails from '../Screens/Subscription/subscriptionDetails';
import Login from '../Screens/Auth/Login';
import SignUp from '../Screens/Auth/SignUp';
import Subscription from '../Screens/Subscription/Subscription';
import UserAction from '../Screens/Subscription/Action.';
import { Avatar, Tooltip, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { logoutAction } from './apiAction';
// import ResetPassword from '../Screens/Auth/RequestResetLink';
import RequestResetLink from '../Screens/Auth/RequestResetLink';
import ResetPassword from '../Screens/Auth/ResetPassword';

export default function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const userToken = JSON.parse(localStorage.getItem('userToken'));
  const loggedInUser = JSON.parse(localStorage.getItem('loginUser'));
  const isLoggedIn = !!userToken; // Convert userToken to a boolean

  async function onLogout() {
    await logoutAction()
      .then(({ error, message }) => {
        if (!error) {
          localStorage.clear();
          navigate('/Login', { replace: true });
        } else {
          if (message === 'Signature has expired.') {
            notification.error({ message: 'Session TimeOut Logging out User' });

            localStorage.clear();
            navigate('/Login', { replace: true });
          } else notification.error({ message });
        }
      })
      .catch((error) => console.log(error));
  }

  const authRoutes =
    location.pathname === '/Login' ||
    location.pathname === '/Signup' ||
    location.pathname === '/reset_password' ||
    location.pathname === '/set_new_password';

  return (
    <div
      className={`bg-tertiary h-screen font-sans relative text-primary px-96 flex flex-col  items-center  overflow-y-scroll overflow-x-hidden`}
    >
      <div
        className='bg-primary absolute'
        style={{
          borderRadius: 1000,
          width: 1000,
          aspectRatio: 1,
          left: '-30%',
          top: '-40%',
          opacity: 0.3,
        }}
      />

      <div
        className='bg-primary absolute'
        style={{
          borderRadius: 1000,
          width: 1000,
          aspectRatio: 1,
          right: '-30%',
          bottom: '-10%',
          opacity: 0.3,
          overflow: 'hidden',
        }}
      />

      <div
        className={` z-10 nav-bar w-screen flex flex-row justify-center items-center relative  `}
      >
        <img
          onClick={() => navigate('/', { replace: true })}
          style={{
            left: 40,
          }}
          className='w-52 absolute '
          src={require('../Assets/logo_white.png')}
          alt='Logo'
        />

        <div className={`${authRoutes && 'hidden'}`}>
          <Link
            to={'/pricing'}
            className={`nav-link ${
              location.pathname === '/pricing'
                ? 'text-primary_highlight'
                : 'text-primary'
            }  m-10 mt-10 hover:text-primary_highlight cursor-pointer`}
          >
            Pricing
          </Link>
          <Link
            to={'/subscription_details'}
            className={`nav-link ${!isLoggedIn && 'hidden'} ${
              location.pathname === '/subscription_details'
                ? 'text-primary_highlight'
                : 'text-primary'
            }  m-10 mt-10 hover:text-primary_highlight cursor-pointer`}
          >
            My Subscription
          </Link>
          <Link
            to={'/action'}
            className={`nav-link ${!isLoggedIn && 'hidden'} ${
              location.pathname === '/action'
                ? 'text-primary_highlight'
                : 'text-primary'
            }  m-10 mt-10 hover:text-primary_highlight cursor-pointer`}
          >
            Action
          </Link>

          {isLoggedIn ? (
            <>
              <Tooltip title={loggedInUser.name || 'Logged In'} placement='top'>
                <Avatar
                  // style={{ backgroundColor: 'red', verticalAlign: 'middle' }}
                  size='large'
                  className='border-2 border-white bg-tertiary text-center'
                  // gap={gap}
                >
                  <UserOutlined className='text-lg' />
                </Avatar>
              </Tooltip>
              <button
                onClick={onLogout}
                className=' ml-5 text-primary font-sans hover:bg-primary hover:text-primary border-2 px-5 py-1 border-white rounded-md'
              >
                logout
              </button>
            </>
          ) : (
            <Link
              to={'/Login'}
              className={`nav-link ${
                location.pathname === '/Login'
                  ? 'text-primary_highlight'
                  : 'text-primary'
              }  m-10 mt-10 hover:text-primary_highlight cursor-pointer`}
            >
              Login / Sign-up
            </Link>
          )}
        </div>
      </div>

      <div
        style={{
          minWidth: '100%',
          marginTop: '5%',
          marginBottom: '10%',
          padding: 20,
        }}
        className={`flex flex-col items-center rounded-2xl bg-secondary drop-shadow-3xl ${
          authRoutes && 'self-center'
        }`}
      >
        <Routes>
          <Route path='*' element={<Navigate to='/pricing' replace={true} />} />
          <Route path='/' element={<Navigate to='/pricing' replace={true} />} />
          <Route path='/Login' element={<Login />} />
          <Route path='/reset_password' element={<RequestResetLink />} />
          <Route path='/set_new_password' element={<ResetPassword />} />
          <Route path='/Signup' element={<SignUp />} />
          <Route path='/pricing' element={<Subscription />} />
          <Route
            path='/subscription_details'
            element={
              !isLoggedIn ? <Navigate to='/Login' /> : <SubscriptionDetails />
            }
          />
          <Route
            path='/action'
            element={!isLoggedIn ? <Navigate to='/Login' /> : <UserAction />}
          />
        </Routes>
      </div>
    </div>
  );
}
