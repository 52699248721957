import { Button, Form, Input, notification } from 'antd';
import React, { useEffect } from 'react';
import { setNewPasswordAction } from './Action';
import { LockOutlined } from '@ant-design/icons';
import { SmileFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export default function ResetPassword() {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('new_jwt_token');

  useEffect(() => {
    if (!token) navigate('/Login', { replace: true });
  }, [token]);

  const onFinish = (values) => {
    const params = {
      token: token,
      password1: values.password,
      password2: values.confirmPassword,
    };

    setNewPasswordAction({ payload: params })
      .then(({ error, message }) => {
        if (!error) {
          api.open({
            message: 'Password Changed Successfully',
            description: 'Redirecting To Login Screen',
            key: 'asdf',
            icon: <SmileFilled />,
          });

          setTimeout(() => {
            api.destroy('asdf');
            navigate('/Login', { replace: true });
          }, 3000);
        } else {
          notification.error({ message });
        }
      })
      .catch((err) => console.log('err', err));
  };

  const validatePassword = (rule, value, callback) => {
    const password = value;
    const confirmPassword = form.getFieldValue('confirmPassword'); // Get the value of the Confirm Password field
    if (password && confirmPassword && password !== confirmPassword) {
      callback('Passwords do not match');
    } else {
      callback();
    }
  };

  return (
    <>
      {contextHolder}
      <LockOutlined className=' text-5xl mb-5' />
      {/* <ExclamationCircleOutlined className=' text-5xl mb-5' /> */}
      <h1 className='text-xl font-bold leading-tight tracking-tight text-center mb-10'>
        Set New Password
      </h1>

      {/* <p className='w-80 text-xs my-3 text-center mb-10'>
        Please provide your email address, and we will promptly send you a link
        to reset your password.
      </p> */}

      <Form onFinish={onFinish} autoComplete='off' className='w-72 font-sans '>
        <p className=' text-primary'>Password:</p>
        <Form.Item
          name='password'
          rules={[
            {
              required: true,
              message: 'Please enter your password!',
            },
            {
              validator: validatePassword, // Use the custom validator
            },
          ]}
        >
          <Input.Password placeholder='Password' />
        </Form.Item>

        <p className=' text-primary'>Confirm Password:</p>
        <Form.Item
          name='confirmPassword'
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            {
              validator: validatePassword, // Use the custom validator
            },
          ]}
        >
          <Input.Password placeholder='Confirm Password' />
        </Form.Item>

        <Form.Item>
          <Button
            // loading={loading}
            htmlType='submit'
            className='w-full h-10 mt-5 text-button_primary bg-primary_button font-medium rounded-lg text-sm py-2.5 text-center'
          >
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
