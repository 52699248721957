import { Form, Input, Spin, notification } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signUpUrl } from './Api';
import { signUpUserAction } from './Action';
import { SmileFilled } from '@ant-design/icons';

export default function SignUp() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const validateEmail = (rule, value, callback) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/.test(value)) {
      callback(); // Validation passed
    } else {
      callback('Invalid email format'); // Validation failed
    }
  };

  const validatePassword = (rule, value, callback) => {
    const password = value;
    const confirmPassword = form.getFieldValue('confirmPassword'); // Get the value of the Confirm Password field
    if (password && confirmPassword && password !== confirmPassword) {
      callback('Passwords do not match');
    } else {
      callback();
    }
  };

  function onFinish(values) {
    const payload = {
      email: values.email,
      name: values.username,
      password: values.password,
    };

    signUpUserAction({ payload })
      .then(({ error, message }) => {
        if (error) notification.error({ message: message });
        else {
          api.open({
            message: 'SignUp Successful',
            description: 'User registration was successful. Please check your inbox to verify the user.',
            key: 'asdf',
            icon: <SmileFilled className='text-Success' />,
          });

          setTimeout(() => {
            api.destroy('asdf');
            navigate('/Login', { replace: true });
          }, 3000);
        }
      })
      .catch((error) => {
        notification.error({ message: `Sign up failed: ${error}` });
      });
  }

  return (
    <>
      {contextHolder}
      <div className=''>
        <h1 class='text-xl font-bold leading-tight tracking-tight text-center'>
          Create An account
        </h1>

        <Form
          onFinish={onFinish}
          autoComplete='off'
          className='w-72 mt-5 font-sans text-base'
          form={form}
        >
          <p className=' text-primary'>Username:</p>
          <Form.Item
            name='username'
            rules={[{ required: true, message: 'Please Enter Username' }]}
          >
            <Input placeholder='Username' />
          </Form.Item>

          <p className=' text-primary'>Email:</p>
          <Form.Item
            name='email'
            rules={[
              {
                required: true,
                message: 'Please enter your email!',
              },
              {
                validator: validateEmail,
              },
            ]}
          >
            <Input placeholder='Email' />
          </Form.Item>

          <p className=' text-primary'>Password:</p>
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: 'Please enter your password!',
              },
              {
                validator: validatePassword, // Use the custom validator
              },
            ]}
          >
            <Input.Password placeholder='Password' />
          </Form.Item>

          <p className=' text-primary'>Confirm Password:</p>
          <Form.Item
            name='confirmPassword'
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              {
                validator: validatePassword, // Use the custom validator
              },
            ]}
          >
            <Input.Password placeholder='Password' />
          </Form.Item>

          <Form.Item>
            <button
              type='submit'
              class='w-full mt-5 text-button_primary bg-primary_button font-medium rounded-lg text-sm px-5 py-2.5 text-center'
            >
              Sign Up
            </button>
          </Form.Item>
        </Form>

        {/* <Spin /> */}

        <p class='text-sm font-light mt-2'>
          Already an Existing User?{' '}
          <a
            onClick={() => navigate('/Login')}
            class=' text-primary font-semibold hover:underline hover:text-primary_highlight '
          >
            Sign in
          </a>
        </p>
      </div>
    </>
  );
}
