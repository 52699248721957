import { Button, Form, Input, Spin, notification } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMeAction, signInUserAction } from './Action';
import { validateEmail } from '../../Utils/setting';

export default function Login() {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const [loading, setLoading] = useState(false);

  // const validateEmail = (rule, value, callback) => {
  //   if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/.test(value)) {
  //     callback(); // Validation passed
  //   } else {
  //     callback('Invalid email format'); // Validation failed
  //   }
  // };

  const onFinish = async (values) => {
    setLoading(true);

    const payload = {
      username: values.email,
      password: values.password,
    };

    await signInUserAction({ payload })
      .then(async ({ message, error, data }) => {
        if (!error) {
          // Get loggedIn User Details
          getMeAction({ token: data.token })
            .then(({ error: error2, data: data2 }) => {
              if (!error2) {
                if(!data2.is_verified) return notification.error({message: "User not yet verified. Please check your inbox and complete the verification process.", duration: 7})

                localStorage.setItem('loginUser', JSON.stringify(data2));

                localStorage.setItem('userToken', JSON.stringify(data.token));

                api.open({
                  message: 'Logged-In Successful',
                  description: 'Redirecting...',
                  key: 'asdf',
                  icon: <Spin />,
                });

                setTimeout(() => {
                  setLoading(false);

                  api.destroy('asdf');
                  navigate('/', { replace: true });
                }, 3000);
              } else {
                notification.error({ message: 'Something Went Wrong' });
              }
            })
            .catch((err) => console.log(err));
        } else notification.error({ message });
      })
      .catch((error) => {
        notification.error({ message: `Sign In failed: ${error}` });
      });

    setLoading(false);
  };

  return (
    <>
      {contextHolder}
      <div>
        <h1 className='text-xl font-bold leading-tight tracking-tight text-center'>
          Sign in to your account
        </h1>

        <Form
          onFinish={onFinish}
          autoComplete='off'
          className='w-72 font-sans '
        >
          <p className=' text-primary text'>Email:</p>
          <Form.Item
            name='email'
            rules={[
              {
                required: true,
                message: 'Please enter your email!',
              },
              {
                validator: validateEmail,
              },
            ]}
          >
            <Input placeholder='Email' />
          </Form.Item>

          <p className=' text-primary'>password:</p>
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: 'Please enter your password!',
              },
            ]}
          >
            <Input.Password placeholder='Password' />
          </Form.Item>

          <div className='flex items-center justify-between'>
            <div className='flex items-start'></div>
            <a
              onClick={() => navigate('/reset_password')}
              className='text-sm font-medium text-primary hover:underline hover:text-primary_highlight'
            >
              Forgot password?
            </a>
          </div>

          <Form.Item>
            <Button
              loading={loading}
              htmlType='submit'
              className='w-full h-10 mt-5 text-button_primary bg-primary_button font-medium rounded-lg text-sm py-2.5 text-center'
            >
              Sign in
            </Button>
          </Form.Item>
        </Form>

        <p className='text-sm font-light mt-2'>
          Don’t have an account yet?{' '}
          <a
            onClick={() => navigate('/Signup')}
            className=' text-primary font-semibold hover:underline hover:cursor-pointer hover:text-primary_highlight '
          >
            Sign up
          </a>
        </p>
      </div>
    </>
  );
}
