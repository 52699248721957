import { API_URL } from "../../../Utils/setting";

export const getAllSubscriptionUrl = API_URL + '/stripe-subscriptions/subscription-products'

export const createSubscriptionUrl = API_URL + '/stripe-subscriptions/create'

export const getAllMySubscriptionsUrl = API_URL + '/stripe-subscriptions/get-all-subscriptions'

export const pauseSubscriptionUrl = API_URL + '/stripe-subscriptions/pause-subscription'

export const resumeSubscriptionUrl = API_URL + '/stripe-subscriptions/resume-subscription'

export const cancelSubscriptionUrl = API_URL + '/stripe-subscriptions/cancel-subscription'