import React, { useEffect, useState } from 'react';
import './subscription.css';
import { notification } from 'antd';
import {
  billingCycleEnums,
  currencyEnums,
  getKeyByValue,
} from '../../Utils/setting';
import {
  createSubscriptionAction,
  getAllSubscriptionAction,
} from './apiAction';

export default function Subscription() {
  // Cache Memmory
  const cacheAllSubscriptions = localStorage.getItem('allSubscriptions');

  const userToken = JSON.parse(localStorage.getItem('userToken'));
  // const loggedInUser = JSON.parse(localStorage.getItem('loginUser'));
  const isLoggedIn = !!userToken; // Convert userToken to a boolean

  const [allSubscriptions, setAllSubscriptions] = useState(
    cacheAllSubscriptions ? JSON.parse(cacheAllSubscriptions) : []
  );

  const allSubscription = async () => {
    getAllSubscriptionAction()
      .then(({ error, message, data }) => {
        if (!error) {
          const sorted = data.sort((a, b) => a.billing_cycle - b.billing_cycle);
          localStorage.setItem('allSubscriptions', JSON.stringify(sorted));
          setAllSubscriptions(sorted);
        } else {
          notification.error({ message });
        }
      })
      .catch((error) => notification.error({ message: error }));
  };

  useEffect(() => {
    allSubscription();
  }, []);

  const onSubscribe = async (id) => {
    await createSubscriptionAction({
      payload: {
        subscription_product_id: id,
      },
    })
      .then(({ error, message, data }) => {
        if (!error) {
          window.location.href = data.url;
        } else {
          notification.error({ message });
        }
      })
      .catch((err) => console.log('err', err));
  };

  return (
    <>
      <h2 className='text-3xl font-semibold mt-10'>Pricing Plans</h2>
      {/* <p className='text-secondary mt-5 w-96 text-center'>
        Our Pricing plans are designed to be affordable, flxible, and tailored
        to your Unique needs.
      </p> */}
      <p className='text-secondary mt-5 w-96 text-center'>
        Each Plan includes 1 License key per purchase, valid for 1 Machine All
        Plans come with Lifetime free updates Supported for Windows
        Desktop/Laptop Only.
      </p>

      <div className='p-10 flex flex-row justify-between mb-10'>
        {allSubscriptions.map((vv) => (
          <div
            style={{
              minHeight: 250,
            }}
            className='mx-5 bg-purple-500 text-white rounded-lg border-4 border-primary w-56 relative drop-shadow-default bg-secondary hover:scale-105 transition-transform duration-300'
          >
            <div className='p-4 pt-10 pb-10'>
              <h1 className='text-2xl font-bold text-center'>
                {getKeyByValue(billingCycleEnums, vv.billing_cycle)}
              </h1>

              <div className='text-black mt-4 text-5xl font-semibold relative ml-2 flex flex-row items-center justify-center text-primary '>
                <p className='text-center relative'>
                  {' '}
                  <span
                    style={{
                      left: -10,
                    }}
                    className='absolute text-base font-normal text-primary'
                  >
                    {vv.currency === currencyEnums['INR'] ? '₹' : '$'}
                  </span>
                  {vv.price}
                </p>
              </div>
              {/* <p className='text-black mt-4 text-center'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis
                voluptate libero, omnis nesciunt.
              </p> */}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '1rem',
                width: '100%',
                bottom: -22,
              }}
              className='absolute '
            >
              <button
                onClick={() => {
                  if (!isLoggedIn)
                    return notification.error({
                      message:
                        'Please sign in or create an account to access our subscription services.',
                    });
                  else onSubscribe(vv.subscription_product_id);
                }}
                className='shine-button text-white py-2 px-4 rounded bg-primary_button text-button_primary w-40 rounded-l-3xl rounded-r-3xl drop-shadow-default font-semibold'
              >
                {'Subscribe'}
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
